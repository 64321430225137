import { publicIdToLocal } from "@utils/cloudinary";
export const patentItems = [
    publicIdToLocal["biel/patent/patent_one"],
    publicIdToLocal["biel/patent/patent_two"],
    publicIdToLocal["biel/patent/patent_three"],
    publicIdToLocal["biel/patent/patent_four"],
    publicIdToLocal["biel/patent/patent_five"],
    publicIdToLocal["biel/patent/patent_six"],
    publicIdToLocal["biel/patent/patent_serven"],
    publicIdToLocal["biel/patent/patent_eight"],
];
export const charityItems = [
    publicIdToLocal["biel/patent/charity_one"],
    publicIdToLocal["biel/patent/charity_two"],
    publicIdToLocal["biel/patent/charity_three"],
    publicIdToLocal["biel/patent/charity_four"],
    publicIdToLocal["biel/patent/charity_five"],
    publicIdToLocal["biel/patent/charity_six"],
    publicIdToLocal["biel/patent/charity_serven"],
]

export const honorItems = [
    publicIdToLocal["biel/patent/honor_1"],
    publicIdToLocal["biel/patent/honor_2"],
    publicIdToLocal["biel/patent/honor_3"],
    publicIdToLocal["biel/patent/honor_4"],
    publicIdToLocal["biel/patent/honor_6"],
    publicIdToLocal["biel/patent/honor_9"],
    publicIdToLocal["biel/patent/honor_10"],
    publicIdToLocal["biel/patent/honor_5"],
    publicIdToLocal["biel/patent/honor_7"],
    publicIdToLocal["biel/patent/honor_11"],
    publicIdToLocal["biel/patent/honor_15"],
    publicIdToLocal["biel/patent/honor_22"],
]

export const culturePc = [
    "/img/about-culture/封面-20.jpg",
    "/img/about-culture/封面-22.jpg",
    "/img/about-culture/文化15.jpg",
    "/img/about-culture/文化14.jpg",
    "/img/about-culture/文化17.jpg",
    "/img/about-culture/封面OK-4.jpg",
    "/img/about-culture/文化16-2.jpg",
    "/img/about-culture/封面29-3.jpg",
]

export const cultureIpad = [
    "/img/about-culture/使命愿景2.jpg",
    "/img/about-culture/使命2.jpg",
    "/img/about-culture/愿景2.jpg",
    "/img/about-culture/价值观2.jpg",
    "/img/about-culture/价值观6.jpg",
    "/img/about-culture/价值观8.jpg",
    "/img/about-culture/价值观4.jpg",
    "/img/about-culture/价值观10.jpg",
]

export const culturePhone = [
    "/img/about-culture/使命愿景1.jpg",
    "/img/about-culture/使命1.jpg",
    "/img/about-culture/愿景1.jpg",
    "/img/about-culture/价值观1.jpg",
    "/img/about-culture/价值观5.jpg",
    "/img/about-culture/封面OK-4.jpg",
    "/img/about-culture/价值观3.jpg",
    "/img/about-culture/价值观9.jpg",
]

