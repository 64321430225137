import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, Tabs } from "antd";
import { graphql } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chunk } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import JumbotronComponent from "@components/Jumbotron";
import Layout from "@components/Layout";
import LocationContext from "@components/LocationContext";
import SEO from "@components/SEO";
import SubHeading from "@components/Subheading";
import { charityItems, cultureIpad, culturePc, culturePhone, honorItems, patentItems } from '@utils/about-imgs';
import { publicIdToLocal } from "@utils/cloudinary";

import office from "@img/biel_office.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { contentConfig } from "./about-page/config";
import { CarouselCSS, CorporateCultureCSS, LocationCSS, TextIntroCSS, VoyageCSS } from "./about-page/styles";



const { TabPane } = Tabs;

const parseAddresses = addresses =>
  addresses.split("\n").map((address, unique) => <p key={unique} className="address">{address}</p>);

const parseMain = paragraphs =>
  paragraphs.split("\n").map((para, index) => <p key={index}>{para}</p>);

const HistoryCarouselComponent = ({ t, largeImgUrl, smallImgUrl }) => {
  const isBrowser = typeof window !== "undefined"
  const [currentIndex, setCurrentIndex] = useState(0);
  const [historyCol, setHistoryCol] = useState();
  useEffect(() => {
    if (isBrowser) {
      const updateHistoryColNum = () => {
        if (window.innerWidth < 1024) {
          setHistoryCol(1);
        } else {
          setHistoryCol(2);
        }
      };
      updateHistoryColNum()
      window.addEventListener("resize", updateHistoryColNum);
    }

  }, []);

  const carouselRef = useRef();
  const handlePrevClick = () => {
    if (carouselRef && carouselRef.current && currentIndex !== 0) {
      carouselRef.current.prev();
    }
  };

  const handleAfterClick = () => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handleBeforeChange = (_, to) => {
    setCurrentIndex(to);
  };
  return (
    <CarouselCSS
      id="history"
      historyCol={historyCol}
      largeImgUrl={largeImgUrl}
      smallImgUrl={smallImgUrl}
    >
      <section>
        <SubHeading text={t("history")} />
        <div className="carousel-wrapper">
          <div className="carousel-navigation-prev" onClick={handlePrevClick}>
            <LeftOutlined />
          </div>
          <Carousel ref={carouselRef} beforeChange={handleBeforeChange}>
            {chunk(contentConfig(t), historyCol).map((split, index) => {
              return (
                <div className="carousel-content" key={index}>
                  <div className="carousel-columns">
                    <div className="carousel-subcolumn-wrapper">
                      {split.map(({ year, content }, unique) => (
                        <div key={unique}>
                          <h3>{year}</h3>
                          <p>{content}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div className="carousel-navigation-after" onClick={handleAfterClick}>
            <RightOutlined />
          </div>
        </div>
      </section>
    </CarouselCSS>
  );
};

const LocationComponent = ({ t }) => {
  return (
    <LocationCSS id="locations">
      <div className="container">
        <section>
          <SubHeading text={t("officeLocations")} />
          <img src={office} alt="office" />
          <Tabs defaultActiveKey="hk" size="large" centered>
            {["hk", "cn", "ch"].map(prefix => (
              <TabPane tab={t(prefix)} key={prefix}>
                {parseAddresses(t(`${prefix}Addresses`))}
              </TabPane>
            ))}
          </Tabs>
        </section>
      </div>
    </LocationCSS>
  );
};

const CorporateCulture = ({ t, data, largeImgUrl, smallImgUrl }) => {
  const { i18n } = useTranslation();
  const [cultureImgs, setCultureImgs] = useState([])

  const index = data.locales.edges.findIndex((value, index) => value.node.ns === "about")
  const isBrowser = typeof window !== "undefined"
  const [currentIndex, setCurrentIndex] = useState(0);
  const [historyCol, setHistoryCol] = useState();
  useEffect(() => {
    if (isBrowser) {
      const updateHistoryColNum = () => {
        if (window.innerWidth < 1024) {
          setHistoryCol(1);
        } else {
          setHistoryCol(2);
        }
      };
      updateHistoryColNum()
      window.addEventListener("resize", updateHistoryColNum);
      if (window.innerWidth > 1000) {
        setCultureImgs(culturePc)
      } else if (window.innerWidth < 1000 || window.innerWidth > 640) {
        setCultureImgs(cultureIpad)
      } else {
        setCultureImgs(culturePhone)
      }
      console.log(window.innerWidth, 'window.innerWidthwindow.innerWidth');
    }

  }, []);

  const carouselRef = useRef();
  const handlePrevClick = () => {
    if (carouselRef && carouselRef.current && currentIndex !== 0) {
      carouselRef.current.prev();
    }
  };

  const handleAfterClick = () => {
    if (
      carouselRef &&
      carouselRef.current &&
      currentIndex !== (historyCol === 2 ? 6 : 12)
    ) {
      carouselRef.current.next();
    }
  };

  const handleBeforeChange = (_, to) => {
    setCurrentIndex(to);
  };

  return (
    <CorporateCultureCSS
      id="culture"
      historyCol={historyCol}
      largeImgUrl={largeImgUrl}
      smallImgUrl={smallImgUrl}
      i18n={i18n.language}
    >
      <div className="">
        <SubHeading text={t("history")} />
        <div className="carousel-wrapper">
          <div className="carousel-navigation-prev" onClick={handlePrevClick}>
            {/* <LeftOutlined /> */}
          </div>
          <Carousel ref={carouselRef} beforeChange={handleBeforeChange} autoplay autoplaySpeed={7500}>
            {
              cultureImgs && cultureImgs.map((res, index) => {
                return (
                  <>
                    <img src={res} alt="" />
                  </>
                )
              })
            }

          </Carousel>
          <div className="carousel-navigation-after" onClick={handleAfterClick}>
            {/* <RightOutlined /> */}
          </div>
        </div>
      </div>
    </CorporateCultureCSS>
  )
}

const VoyageComponent = ({ t, data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const index = data.locales.edges.findIndex((value, index) => value.node.ns === "about")
  const patentedTechnologyData = JSON.parse(data.locales.edges[index].node.data).patentedTechnology
  patentedTechnologyData && patentedTechnologyData.map(res => {
    if (res.flag === "technology") {
      res.imgs = patentItems
    } else if (res.flag === "honor") {
      res.imgs = charityItems
    } else {
      res.imgs = honorItems
    }
  })

  return (
    <VoyageCSS id="honor">
      <div className="container">
        <section>
          <SubHeading text={t("voyageWithHonor")} />
          {
            patentedTechnologyData && patentedTechnologyData.map((res, index) => {
              return <div className="honor-block" key={index}>
                <div className="caption">{res.title}</div>
                <div className="content">{res.content}</div>
                <div className="carousel-honor">
                  <div></div>
                  <Slider {...settings} >
                    {Array.from(res.imgs).map((url, index) => {
                      return (
                        <div className="carousel-content" key={index}>
                          <img src={url} width="50%" />
                        </div>
                      );
                    })}
                  </Slider>
                  <div></div>
                </div>
              </div>
            })
          }
        </section>
      </div>
    </VoyageCSS>
  );
}

const TextIntroComponent = ({ t }) => {
  return (
    <TextIntroCSS id="introduction">
      <div className="container">
        <section id="about-subtitle">
          <div className="subtitle">
            {parseMain(t("aboutMain"))}
          </div>
        </section>
      </div>
    </TextIntroCSS>
  );
};

const AboutPageContent = ({ t, data, i18n }) => {
  return (
    <>
      <JumbotronComponent
        t={t}
        imgUrl={publicIdToLocal["biel/factory_jumbotron_hx6g5j"]}
        isJumbotron={true}
      />
      <TextIntroComponent t={t} />
      <HistoryCarouselComponent
        t={t}
        largeImgUrl={publicIdToLocal["biel/about_carousel_bg_sxovop"]}
        smallImgUrl={publicIdToLocal["biel/history_small_lj6mhw"]}
      />
      <VoyageComponent t={t} data={data} />
      <CorporateCulture
        t={t}
        data={data}
        largeImgUrl={publicIdToLocal["biel/about_carousel_bg_sxovop"]}
        smallImgUrl={publicIdToLocal["biel/history_small_lj6mhw"]}
        i18n={i18n}
      />
      <LocationComponent t={t} />
    </>
  );
};

const AboutPage = ({ location, data }) => {

  const { t, i18n } = useTranslation();

  return (
    <Layout location={location} t={t} i18={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <LocationContext.Consumer>
        {props => (
          <AboutPageContent t={t} i18={i18n} {...props} data={data} />
        )}
      </LocationContext.Consumer>
    </Layout>
  );
};

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
};


export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["about","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;


export default AboutPage;
