import styled from 'styled-components'
import C_vision from '../../img/cultrue-origin/C_vision.jpg'

export const TextIntroCSS = styled.div`
  width: 70vw;
  margin: 0 auto;
  @media (min-width: 1024px) {
    padding-top: 100px;
  }

  @media (max-width: 1024px) {
    padding-top: 10vh;
  }

  section {
    padding-bottom: 2rem;

    .subtitle {
      h2 {
        padding-top: 3rem;
        padding-bottom: 1rem;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: green;
      }

      .intro {
        text-align: center
      }

      p {
        font-weight: 500;
        margin-bottom: 1rem;
        line-height: 2rem;
      }

      @media (max-width: 1024px) {
        p {
          font-size: 1rem;
        }
      }
    }
  }
`

export const LocationCSS = styled.div`
  @media (min-width: 1024px) {
    width: 70vw;
  }

  @media (max-width: 1024px) {
    width: 95vw;
  }

  margin: 0 auto;

  h2 {
    padding-top: 3rem;
  }

  .ant-tabs {
    margin-top: -5%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: green;
  }

  .ant-tabs-tab-btn {
    color: #4a4a4a;

    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }

  .ant-tabs-tab  {
    color: #4a4a4a;

    @media (max-width: 600px) {
      margin: 0 15px 0 0;
    }
  }

  .ant-tabs-ink-bar {
    background: green;
  }

  .ant-tabs-tabpane {
    min-height: 200px;
  }

  .address {
    text-align: center;
    font-size: 1rem;
  }

  #about-office-location {
    padding: 0%;
  }
`

export const CarouselCSS = styled.div`
  background-image: url(${props => props.historyCol === 2 ? props.largeImgUrl : props.smallImgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  :global{
    .slick-dots {
      justify-content:normal !imporant;
      color:red
    }
    
  }
   
  h2 {
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: white;
  }
  .carousel-wrapper {
    display: grid;
    grid-template-areas: 
      'prev columns after';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%,  rgb(0, 0, 0, 0.6) 100%);
    @media (min-width: 1024px) {
      grid-template-columns: 15% 70% 15%;
    }
    
    @media (max-width: 1024px) {
      grid-template-columns: 50px calc(100% - 100px) 50px;
    }
    @media (max-width: 600px) {
      grid-template-columns: 0px 100% 0px;
    }
    div.carousel-navigation-prev, div.carousel-navigation-after {
      align-self: center;
      justify-self: center;
    }
    div.carousel-navigation-prev {
      grid-area: prev;
      &:hover {
        cursor: pointer;
      }
    }
    div.carousel-navigation-after {
      grid-area: after;
      &:hover {
        cursor: pointer;
      }
    }
    .ant-carousel {
      grid-area: columns;
      .carousel-content {
        height: 400px;
        > div.carousel-columns {
          height: 100%;
          div.carousel-subcolumn-wrapper {
            display: flex;
            height: calc(100% - 50px);
            div {
              padding: 3rem;
              @media (min-width: 1024px) {
                width: 50%;
              }
              h3 {
                padding-left: 1rem;
                position: absolute;
                font-size: 1.5rem;
                font-weight: 700;
                top: 17%;
                color: #fff;
                @media (max-width: 1024px) {
                  padding-left: 0;
                }
              }
              p {
                padding-left: 1rem;
                position: absolute;
                font-size: 1.2rem;
                top: calc(17% + 40px);
                color: #fff;
                @media (min-width: 1024px) {
                  max-width: 30vw;
                }
                @media (max-width: 1024px) {
                  padding-left: 0;
                  font-size: 1rem;
                  max-width: 85vw;
                }
              }
            }
          }
        }
      }
  }
  .anticon  {
    font-size: 0;
  }
  &:hover {
    .anticon {
      color: white;
      font-size: 3rem;
    }
    @media (max-width: 1024px){
      .anticon {
        height: 100%;
        color: white;
        font-size: 2rem;
      }
    }
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: green;
  }
  .slick-dots li button:before {
    content:''
  }
  .ant-carousel .slick-dots{
    width: auto !important;
  }
}
`

export const VoyageCSS = styled.div`
.slick-list {
  margin-left: 2%;
  margin-right: 2%;
}
.slick-prev {
  left: 0.5%;
  z-index: 99999;
}
.slick-next {
  right: 0.5%;
  z-index: 99999;
}
.slick-prev:before,.slick-next:before {
  color: #aaa !important;
}

.container {
  margin-top:35px;
}

.honor-block {
  margin-top:35px
}
.caption {
  font-size: 1.3rem;
  font-weight:600;
  
  @media screen and (max-width: 830px) {
    text-indent:15px;
  }
}

.content {
  line-height:35px;
  margin-bottom: 1rem;
  color: #4a4a4a;
  width: 70vw;
  margin: 0 auto;
  margin-top:20px;
  
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
  @media (min-width: 1024px) {
    font-size: 1.25rem;
    font-weight:500;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    width: 70vw;
    margin: 0 auto;
  }
}

.carousel-honor {
  display: grid;
  grid-template-areas: "prev columns after";
  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 15% 70% 15%;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 50px calc(100% - 100px) 50px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 0px 100% 0px;
  }

  div.carousel-navigation-after {
    grid-area: after;

  }
  .carousel-content {
    img {
      margin: 0 auto;
    }
  }
  .slick-dots li.slick-active button::before {
    color:green !important;
  }
  .slick-dots li button::before{
    color:green !important;
  }

}
`

export const CorporateCultureCSS = styled.div`
margin-top:25px;
// background-image: url(${props => props.historyCol === 2 ? props.largeImgUrl : props.smallImgUrl});
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  :global{
    .slick-dots {
      justify-content:normal !imporant;
      color:red
    }
    
  }
   
  h2 {
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: white;
  }
  .carousel-wrapper {
    display: grid;
    grid-template-areas: 
      'prev columns after';
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%,  rgb(0, 0, 0, 0.6) 100%);
    @media (min-width: 1024px) {
      grid-template-columns: 15% 70% 15%;
    }
    
    @media (max-width: 1024px) {
      grid-template-columns: 50px calc(100% - 100px) 50px;
    }
    @media (max-width: 600px) {
      grid-template-columns: 0px 100% 0px;
    }
    div.carousel-navigation-prev, div.carousel-navigation-after {
      align-self: center;
      justify-self: center;
    }
    div.carousel-navigation-prev {
      grid-area: prev;
      &:hover {
        cursor: pointer;
      }
    }
    div.carousel-navigation-after {
      grid-area: after;
      &:hover {
        cursor: pointer;
      }
    }
    .ant-carousel {
      grid-area: columns;
      .carousel-content {
        height: 400px;
        > div.carousel-columns {
          height: 100%;
          div.carousel-subcolumn-wrapper {
            display: flex;
            height: calc(100% - 50px);
            div {
              padding: 3rem;
              @media (min-width: 1024px) {
                width: 50%;
              }
              h3 {
                padding-left: 1rem;
                position: absolute;
                font-size: 1.5rem;
                font-weight: 700;
                top: 17%;
                color: #fff;
                @media (max-width: 1024px) {
                  padding-left: 0;
                }
              }
              p {
                padding-left: 1rem;
                position: absolute;
                font-size: 1.2rem;
                top: calc(17% + 40px);
                color: #fff;
                @media (min-width: 1024px) {
                  max-width: 30vw;
                }
                @media (max-width: 1024px) {
                  padding-left: 0;
                  font-size: 1rem;
                  max-width: 85vw;
                }
              }
            }
          }
        }
      }
  }
  .anticon  {
    font-size: 0;
  }
  &:hover {
    .anticon {
      color: white;
      font-size: 3rem;
    }
    @media (max-width: 1024px){
      .anticon {
        height: 100%;
        color: white;
        font-size: 2rem;
      }
    }
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: green;
  }
  .slick-dots li button:before {
    content:''
  }
  .ant-carousel .slick-dots{
    width: auto !important;
  }

  // 设置样式
  .mission-vision {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width:100%;
    height:600px;
  }
  .a {
    
    color:white;
    @media (min-width: 1024px) {
      width:320px;
      height:320px;
      position: relative;
      top: 40%;
      left:-45%;
      transform: translate( -50%, -50%);
    }
    
    @media (max-width: 1024px) {
      width:320px;
      height:320px;
      position: relative;
      top: 40%;
      left:-45%;
      transform: translate( -50%, -50%);
    }
    @media (max-width: 600px) {
      width:200px;
      height:200px;
      position: relative;
      top: 40%;
      left:-27%;
      transform: translate( -50%, -50%);
    }
  }
  .rhombus_header {
    width: 400px;
    height: 400px;
    margin: 0 auto;
  }

  .diamond {
    transform: rotate(45deg);
    background: rgb(66 102 102 /80%);
    @media (min-width: 1024px) {
      width: 170px;
      height: 170px;
    }
    @media (max-width: 1024px) {
      width: 170px;
      height: 170px;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
    }
  }
  .diamond1 {
    transform: rotate(45deg);
    background: rgb(66 102 102 /80%);
    @media (min-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top:82px;
    }
    @media (max-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top:82px;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      margin-top:62px;
    }
  }
  .diamond2 {
    transform: rotate(45deg);
    position: relative;
    background: rgb(66 102 102 /80%);
    @media (min-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top: -304px;
      margin-left:128px;
    }
    
    @media (max-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top: -304px;
      margin-left:128px;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      margin-top: -190px;
      margin-left:78px;
    }
  }
  .diamond3 {
    transform: rotate(45deg);
    position: relative;
    background: rgb(66 102 102 /80%);
    @media (min-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top: -182px;
      margin-left:-128px;
    }
    @media (max-width: 1024px) {
      width: 170px;
      height: 170px;
      margin-top: -182px;
      margin-left:-128px;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      margin-top: -112px;
      margin-left:-78px;
    }
  }
  .diamond4 {
    transform: rotate(45deg);
    position: relative;
    background: rgb( 255 255 255 /100%);
    @media (min-width: 1024px) {
      width: 110px;
      height: 110px;
      margin-top: -150px;
      margin-left:35px;
    }
    @media (max-width: 1024px) {
      width: 110px;
      height: 110px;
      margin-top: -150px;
      margin-left:35px;
    }
    @media (max-width: 600px) {
      width: 75px;
      height: 75px;
      margin-top: -92px;
      margin-left:10px;
    }
  }

  .diamond_r {
    transform: rotate(-45deg);
    display: block;
    font-weight:700;
    margin:10px;
    @media (min-width: 1024px) {
      font-size: ${props => (props.i18n === 'en' ? "1rem" : "1.8rem")};
    }
    @media (max-width: 1024px) {
      font-size: ${props => (props.i18n === 'en' ? "1rem" : "1.5rem")};
    }
    @media (max-width: 600px) {
      font-size: ${props => (props.i18n === 'en' ? "0.5rem" : "0.8rem")};
    }
  }
  .diamond_r1 {
    @media (min-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "80% 0%" : "60% 0%")}; 
    }
    @media (max-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "80% 0%" : "60% 0%")}; 
    }
    @media (max-width: 600px) {
      transform-origin: ${props => (props.i18n === 'en' ? "80% 0%" : "45% 0%")}; 
    }
  }
  .diamond_r2 {
    @media (min-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 0%" : "80% 0%")};
      margin-left: ${props => (props.i18n === 'en' ? "15px" : "20px")};
    }
    @media (max-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 0%" : "80% 0%")};
      margin-left: ${props => (props.i18n === 'en' ? "15px" : "20px")};
    }
    @media (max-width: 600px) {
      transform-origin: 90% 0%;
      margin-left:20px;
    }
  }
  .diamond_r3 {
    @media (min-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 0%" : "60% 10%")};
      margin-left: ${props => (props.i18n === 'en' ? "15px" : "35px")};
    }
    
    @media (max-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 0%" : "60% 10%")};
      margin-left: ${props => (props.i18n === 'en' ? "15px" : "35px")};
    }
    @media (max-width: 600px) {
      transform-origin: 60% 10%;
      margin-left:35px;
    }
  }
  .diamond_r4 {
    @media (min-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 10%" : "65% 10%")};
      margin-left: ${props => (props.i18n === 'en' ? "-10px" : "")};
    }
    
    @media (max-width: 1024px) {
      transform-origin: ${props => (props.i18n === 'en' ? "90% 10%" : "65% 10%")};
      margin-left: ${props => (props.i18n === 'en' ? "-10px" : "")};
    }
    @media (max-width: 600px) {
      transform-origin: 65% 0%;
    }
  }
  .diamond_h {
    transform: rotate(-45deg);
    display: block;
    font-weight:700;
    margin:10px;
    color:black;
    
  }
  .diamond_1 {
    @media (min-width: 1024px) {
      font-size: ${props => (props.i18n === 'en' ? "1.2rem" : "1rem")};
      transform-origin: ${props => (props.i18n === 'en' ? "60% 0%" : "50% 0%")};
      margin-left: ${props => (props.i18n === 'en' ? "0px" : "-15px")};
    }
    
    @media (max-width: 1024px) {
      font-size: ${props => (props.i18n === 'en' ? "1.2rem" : "1rem")};
      transform-origin: ${props => (props.i18n === 'en' ? "60% 0%" : "50% 0%")};
      margin-left: ${props => (props.i18n === 'en' ? "0px" : "-15px")};
    }
    @media (max-width: 600px) {
      font-size:0.8em;
      transform-origin: 60% 0%;
      margin-left:-15px
    }
    
  }
  .diamond_2 {
    @media (min-width: 1024px) {
      font-size:1.3em;
      transform-origin: 60% 0%;
      margin-left: ${props => (props.i18n === 'en' ? "5px" : "-5px")};
    }
    
    @media (max-width: 1024px) {
      font-size:1.3em;
      transform-origin: 60% 0%;
      margin-left:-5px
    }
    @media (max-width: 600px) {
      font-size:0.7em;
      transform-origin: 50% 0%;
      margin-left:3px
    }
    
  }
  
  .culture-content {
    color:white
  }
  .culture-content {
    @media (min-width: 1024px) {
      margin-left:120px;
      position: absolute;
      top: 50%;
      transform: translate( 0, -50%);
    }
    
    @media (max-width: 1024px) {
      margin-left:120px;
      position: absolute;
      top: 50%;
      transform: translate( 0, -50%);
    }
    @media (max-width: 600px) {
      margin-left:15px;
      position: absolute;
      top: 62%;
      transform: translate( 0, -50%);
    }
    
  }
  .culture-rhombus {
    position: absolute;
    top: 50%;
    transform: translate( 0, -50%);
  }

  
  .culture-title {
    font-family: DIN Black, 'arial block', '微软雅黑';
    font-weight:bold;
    @media (min-width: 1024px) {
      font-size: 2.5rem;
    }
    
    @media (max-width: 1024px) {
      font-size: 2.5rem;
    }
    @media (max-width: 600px) {
      font-size: 2rem;
    }
    
  }
  .culture-logo {
    font-family: Vladimir Script, 'arial block', '微软雅黑';
    text-align:center;
    font-size: 2rem;
    color:green;
    margin-top:-25px;

  }
  .culture-headline {
    text-align:center;
    color:white;
    background-color:#1bd1a5;
    width:45%;
    font-family: 等线 (正文), 'arial block', '微软雅黑';
    margin-top:10px;
    @media (min-width: 1024px) {
      font-size: 1.2rem;
    }
    
    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }
    @media (max-width: 600px) {
      font-size: .9rem;
    }
    
  }
  .culture-text-title {
    margin-top:20px;
    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
    
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
  .culture-text {
    line-height: 1;
    margin-top:15px;
    @media (min-width: 1024px) {
      font-size: 1rem;
    }
    
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 0.9rem;
    }

  }

`