export const contentConfig = t => [
  {
    year: 1989,
    content: t('1989'),
  },
  {
    year: 1992,
    content: t('1992'),
  },
  {
    year: 2001,
    content: t('2001'),
  },
  {
    year: 2004,
    content: t('2004'),
  },
  {
    year: 2007,
    content: t('2007'),
  },
  {
    year: 2008,
    content: t('2008'),
  },
  {
    year: 2009,
    content: t('2009'),
  },
  {
    year: 2013,
    content: t('2013'),
  },
  {
    year: 2014,
    content: t('2014'),
  },
  {
    year: 2015,
    content: t('2015'),
  },
  {
    year: 2016,
    content: t('2016'),
  },
  {
    year: 2017,
    content: t('2017'),
  },
  {
    year: 2018,
    content: t('2018'),
  },
  {
    year: 2019,
    content: t('2019'),
  },
  {
    year: 2020,
    content: t('2020'),
  },
  {
    year: 2021,
    content: t('2021'),
  },
  {
    year: 2022,
    content: t('2022'),
  },
  {
    year: 2023,
    content: t('2023'),
  },
  {
    year: 2024,
    content: t('2024'),
  },
  {
    year: t('future'),
    content: t('futureContent'),
  },
];